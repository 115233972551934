import React from "react";
import SingleCounter from "./SingleCounter";
import img from '../../assets/1.jpg'
import img1 from '../../assets/2.jpg'
const style = {
  wrapper: "h-[60%] flex flex-col py-8",
  singleCounter: "flex flex-col items-center justify-center w-[45%] p-4",
  details: " text-gray-900 text-sm md:text-2xl",
  details1:
    " text-gray-900 text-2xl sm:text-2xl md:text-2xl lg:text-4xl font-semibold",
  headingContainer: "flex items-center jusitfy-center flex-col mb-6",
  heading:
    "max-w-fit mx-auto flex items-center jusitfy-center flex-col mb-8 text-gray-900 text-3xl md:text-4xl font-semibold ",
};
const Counter = () => {
  return (
    // main
    <div className={style.wrapper}>
      <section className="m-auto h-[20%] flex py-7 md:py-12">
        <div
          className={style.headingContainer}
          style={{ fontFamily: "Montserrat,serif" }}
        >
          <h2 className={style.heading}>Постижения</h2>
          <p className="border-b-[6px] border-stone-400 w-[8vw] md:w-[4vw] "></p>
        </div>
      </section>
      <div className=" flex justify-around my-6 flex-wrap">
      {/* here put the image */}
      <img src={img} alt="" className="rounded-xl" width={350} height={240}/>
      <img src={img1} alt="" className="rounded-xl" width={450} height={240}/>     
      </div>
    </div>
  );
};

export default Counter;
