import React, { useState, useEffect, useRef } from "react";
import emailjs from "@emailjs/browser";

const style = {
  wrapper:
    " rounded-xl w-[95%] md:w-[55%] lg:w-[50%] mx-auto py-8 md:py-1",
  smallHeading:
    " font-semibold border-b-2 border-primary text-2xl md:text-3xl max-w-fit mx-auto py-2 text-primary my-8 md:my-10 font",
  formContainer: "flex flex-col font w-[90%] md:w-[85%] mx-auto",
  input:
    "rounded-lg font px-3  md:px-4 py-2 md:py-3 ring-none outline-none  my-4 ",
  btn: "rounded-lg bg-opacity-[1] hover:bg-opacity-[0.8] transition duration-[100ms] my-6 px-8 md:px-12 py-2 md:py-3  tracking-wide bg-yellow-500 text-stone-800 font-semibold cursor-pointer w-[100%] md:w-[35%] mx-auto",
};

const ContactForm = () => {
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [Message, setMessage] = useState("");
  const sendEmail = (e) => {
    e.preventDefault();
    // console.log(form.current);

    const formData={
      from_name:Name,
      from_email: Email,
      message: Message
    }
    emailjs
      .send(
        "service_gkdp02u",
        "template_p8tea1l",
        formData,
        "kqAB_D2-geeGn7c7c"
      )
      .then(
        (result) => {
          console.log(result.text)
          setName("");
          setEmail("");
          setMessage("")
        },
        (error) => {
          console.log(error.text);
        }
      );
  };
  return (
    <div className={style.wrapper}   style={{ fontFamily: "Montserrat,serif" }}>
      <form onSubmit={sendEmail} className={style.formContainer}>
        <label className="text-white">Име</label>
        <input required type="text" name="from_name" className={style.input} value={Name} onChange={(e) => setName(e.target.value)}/>
        <label  className="text-white">Email</label>
        <input required type="email" name="from_email" className={style.input} value={Email} onChange={(e) => setEmail(e.target.value)}/>
        <label className="text-white">Съобщение</label>
        <textarea required name="message" className={style.input} rows='10' value={Message} onChange={(e) => setMessage(e.target.value)}/>
        <input type="submit" value="Изпрати" className={style.btn}/>
      </form>
      {/* <form className={style.formContainer} onSubmit={submitHandler}>
        <label htmlFor="" className="">
          Name
        </label>
        <input
          type="name"
          className={style.input}
          value={Name}
          onChange={nameChangeHandler}
        />
        <label htmlFor="" className="">
          Email
        </label>
        <input
          type="email"
          className={style.input}
          value={Email}
          onChange={emailChangeHandler}
        />{" "}
        <label htmlFor="" className="">
          Message
        </label>
        <textarea
          rows="10"
          col="20"
          className={style.input}
          value={Message}
          onChange={messageChangeHandler}
        ></textarea>
        <a
          href={`mailto:'shariqueimam7777@gmail.com'?subject=${Name}&body=${Message}`}
          className={style.btn}
        >
          Send
        </a>
      </form> */}
    </div>
  );
};

export default ContactForm;
