import React from "react";
import ContactForm from "./ContactForm";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
const style = {
  wrapper:
    "w-[100%] md:w-[100%] lg:w-[100%] mx-auto font py-8 md:py-24 bg-stone-800",
  headingContainer: "flex flex-col items-center justify-center pb-6 md:pb-4",
  mainHeading:
    "text-3xl md:text-4xl mb-4 md:mb-4 text-white font-semibold tracking-wide",
  singleContactContainer: "flex items-center justify-center my-4 md:my-8",
  singleContactContainer1: "flex items-center justify-center my-2 md:my-3",
  para: "text-white",
};
const Contact = () => {
  return (
    <div className={style.wrapper} style={{ fontFamily: "Montserrat,serif" }}>
      <div className={style.headingContainer}>
        <h2 className={style.mainHeading}>Контакти</h2>
        <p className="border-b-[6px] border-stone-300 w-[16vw] md:w-[4vw] "></p>
        <a
          className={style.singleContactContainer}
          href="mailto: info@hotel-kris.com"
        >
          <AiOutlineMail className="text-3xl text-primary rounded-full p-1 mx-4 text-stone-100" />
          <p className={style.para}>info@hotel-kris.com</p>
        </a>
        <a className={style.singleContactContainer1}>
          <AiOutlinePhone className="text-3xl text-primary rounded-full p-1 mx-4 text-stone-100" />
          <p className={style.para}>088 930 0220</p>
        </a>
      </div>
      <ContactForm />
    </div>
  );
};

export default Contact;
