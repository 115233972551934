import React, { useState } from "react";
import Navbar from "../Navbar/Navbar";
import useWindowSize from "../../hooks/useWindowSize";
import { AiOutlineArrowRight } from "react-icons/ai";
import styled from "styled-components";
import img1 from "../../assets/visual1.jpg";
import img2 from "../../assets/visual2.jpg";
import img3 from "../../assets/rooms/DSC_0462big.jpg";
const style = {
  wrapper: "h-[100vh] w-[100vw] flex flex-col h-[70%] font2",
  heading:
    "text-5xl md:text-7xl text-white  my-2 md:my-3 w-[100%] md:w-[40%] text-center md:text-left font-semibold",
  para: "tracking-wider text-white text-lg md:text-sm my-1 text-center md:text-left mx-auto md:mx-0 w-[100%] md:w-[50%]",
  btn: " px-8 md:px-6 py-3 md:py-4 transition duration-[200ms] max-w-fit mx-auto md:mx-0 ",
  arrow: `text-xl`,
  btnContainer:
    "cursor-pointer hover:opacity-[0.9]  mx-auto md:mx-0 my-5 flex items-center justify-start bg-white   text-gray-900 w-[80%] md:w-[15%] px-4 rounded",
  mainContent:
    "w-[90%] md:w-[80%] h-[80%] flex flex-col items-start justify-center  mx-auto",
};

const Home = (props) => {
  const { width } = useWindowSize();
  const FeaturedBackground1 = styled.div`
    background: radial-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
      url(${img3});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: auto;
    height: 100vh;
    width: 100vw;
  `;
  const onScroll = (val) => {
    props.onScroll(val);
  };
  return (
    <div className={style.wrapper} style={{ fontFamily: "Montserrat,serif" }}>
      <FeaturedBackground1>
        <Navbar onScroll={onScroll} />
        <div
          className={style.mainContent}
          style={{ fontFamily: "Poppins,serif" }}
        >
          <h2 className={style.heading}>Добре дошли в къща за гости „Крис“, гр. Девин</h2>
          <p className={style.para} style={{ fontFamily: "Montserrat,serif" }}>
          За нас ще бъде истинско удоволствие да сте наши гости. При нас ще намерите тишина, уют и
спокойствие от забързаното си ежедневие.
          </p>
          <div className={style.btnContainer} onClick={()=>props.onScroll('rooms')}>
            <button className={style.btn}>Резервирай</button>
            <AiOutlineArrowRight className={style.arrow} />
          </div>
        </div>
      </FeaturedBackground1>
    </div>
  );
};

export default Home;
