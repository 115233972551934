import React, { useState, useRef } from "react";
import emailjs from "@emailjs/browser";
import dayjs from "dayjs";
import { DatePicker } from '@mui/x-date-pickers';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';

const style = {
  wrapper: "rounded-xl w-[95%] md:w-[95%] lg:w-[95%] mx-auto py-8 md:py-1",
  formContainer: "flex flex-col font w-[90%] md:w-[85%] mx-auto",
  input:
    "rounded-lg font px-3 md:px-4 py-2 md:py-3 ring-none outline-none my-4 text-stone-800 border-2 border-stone-800",
  btn: "rounded-lg bg-opacity-[1] hover:bg-opacity-[0.8] transition duration-[100ms] my-6 px-8 md:px-12 py-2 md:py-3 tracking-wide bg-yellow-500 text-stone-800 font-semibold cursor-pointer w-[100%] md:w-[35%] mx-auto",
  errorMessage: "text-red-600 text-sm mb-2",
};

const ReservationForm = () => {
  const [Name, setName] = useState("");
  const [Email, setEmail] = useState("");
  const [FromDate, setFromDate] = useState(null);
  const [ToDate, setToDate] = useState(null);
  const [RoomType, setRoomType] = useState("");
  const [NumberOfRooms, setNumberOfRooms] = useState("");
  const [errors, setErrors] = useState({});
  const form = useRef();

  const validate = () => {
    const newErrors = {};

    // Validate Name
    if (!Name.trim()) {
      newErrors.Name = "Името е задължително.";
    }

    // Validate Email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!Email.trim()) {
      newErrors.Email = "Имейлът е задължителен.";
    } else if (!emailPattern.test(Email)) {
      newErrors.Email = "Моля, въведете валиден имейл адрес.";
    }

    // Validate Dates
    if (!FromDate) {
      newErrors.FromDate = "Дата от е задължителна.";
    }
    if (!ToDate) {
      newErrors.ToDate = "Дата до е задължителна.";
    } else if (ToDate.isBefore(FromDate)) {
      newErrors.DateRange = "Дата до не може да бъде по-ранна от дата от.";
    }

    // Validate Room Type
    if (!RoomType) {
      newErrors.RoomType = "Тип стая е задължителен.";
    }

    // Validate Number of Rooms
    if (!NumberOfRooms) {
      newErrors.NumberOfRooms = "Брой гости е задължителен.";
    } else if (NumberOfRooms <= 0) {
      newErrors.NumberOfRooms = "Моля, въведете валиден брой стаи.";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (validate()) {
      // Format dates to dd/mm/yyyy
      const formatDate = (date) => {
        return dayjs(date).format("DD/MM/YYYY");
      };

      const formData = {
        from_name: Name,
        from_email: Email,
        from_date: formatDate(FromDate),
        to_date: formatDate(ToDate),
        roomtype: RoomType,
        numberofrooms: NumberOfRooms,

      };

      emailjs
        .send(
          "service_gkdp02u",
          "template_rpmoshl",
          formData,
          "kqAB_D2-geeGn7c7c"
        )
        .then(
          (result) => {
            console.log(result.text);
            // Optionally, clear the form and reset errors
            setName("");
            setEmail("");
            setFromDate(null);
            setToDate(null);
            setRoomType("");
            setNumberOfRooms("");
            setErrors({});
          },
          (error) => {
            console.log(error.text);
          }
        );
    }
  };

  return (
    <div className={style.wrapper} style={{ fontFamily: "Montserrat,serif" }}>
      <form ref={form} onSubmit={sendEmail} className={style.formContainer}>
        <label className="text-stone-800">Име</label>
        <input
          type="text"
          name="from_name"
          className={style.input}
          value={Name}
          onChange={(e) => setName(e.target.value)}
        />
        {errors.Name && <div className={style.errorMessage}>{errors.Name}</div>}

        <label className="text-stone-800">Email</label>
        <input
          type="email"
          name="from_email"
          className={style.input}
          value={Email}
          onChange={(e) => setEmail(e.target.value)}
        />
        {errors.Email && <div className={style.errorMessage}>{errors.Email}</div>}

        <label className="text-stone-800">От дата:</label>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={FromDate}
            onChange={(date) => setFromDate(date)}
            renderInput={(params) => <TextField {...params} className={style.input} />}
            inputFormat="DD/MM/YYYY"
            format="DD/MM/YYYY"
          />
        </LocalizationProvider>
        {errors.FromDate && <div className={style.errorMessage}>{errors.FromDate}</div>}

        <label className="text-stone-800">До дата:</label>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={ToDate}
            onChange={(date) => setToDate(date)}
            renderInput={(params) => <TextField {...params} className={style.input} />}
            inputFormat="DD/MM/YYYY"
            format="DD/MM/YYYY"

          />
        </LocalizationProvider>
        {errors.ToDate && <div className={style.errorMessage}>{errors.ToDate}</div>}
        {errors.DateRange && <div className={style.errorMessage}>{errors.DateRange}</div>}

        <label className="text-stone-800">Тип стая:</label>
        <select
          name="roomtype"
          className={style.input}
          value={RoomType}
          onChange={(e) => setRoomType(e.target.value)}
        >
          <option value="">Select Room Type</option>
          <option value="Двойна стая">Двойна стая</option>
          <option value="Апартамент">Апартамент</option>
        </select>
        {errors.RoomType && <div className={style.errorMessage}>{errors.RoomType}</div>}

        <label className="text-stone-800">Брой гости</label>
        <input
          type="number"
          name="numberofrooms"
          className={style.input}
          value={NumberOfRooms}
          onChange={(e) => setNumberOfRooms(e.target.value)}
        />
        {errors.NumberOfRooms && <div className={style.errorMessage}>{errors.NumberOfRooms}</div>}

        <input
          type="submit"
          value="Send"
          className={style.btn}
        />
      </form>
    </div>
  );
};

export default ReservationForm;
