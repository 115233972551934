import React from "react";
import Counter from "./Counter/Counter";
import aboutimg from "../../assets/rooms/DSC_0499big.jpg";
import { AiOutlineArrowRight } from "react-icons/ai";

const style = {
  about: "w-[95%] md:w-[90%] mx-auto flex flex-col items-center justify-center",
  side1: "w-full md:w-[45%]  mx-auto",
  headingContainer: "flex items-center jusitfy-center flex-col mt-8",
  side2:
    "w-full md:w-[40%] md:scale-[1.3] flex items-center justify-center mx-auto hidden md:flex",
  heading:
    "font-bold text-5xl sm:text-6xl md:text-5xl lg:text-6xl mb-8 text-stone-800",
  details: "text-primary py-3 ",
  divider: "border-b-[1px] border-gray-200 p-2",
  coutnerContainer: "",
  heading1:
  "max-w-fit mx-auto flex items-center jusitfy-center flex-col my-8 text-gray-900 text-3xl md:text-4xl font-semibold ",
  signature:
    "my-8 flex justify-around items-center w-[70%] md:w-[60%] mx-auto md:mx-0",
  img: "",
  name: "text-xl sm:text-xl md:text-3xl lg:text-3xl italic ",
  signatureImg: "scale-[0.7] md:scale-[0.95]",
  btn: " px-8 md:px-6 py-3 md:py-4 transition duration-[200ms] max-w-fit mx-auto md:mx-0 ",
  arrow: `text-xl `,
  arrow1: `ml-3 text-xl`,
  btnContainer:
    "cursor-pointer hover:opacity-[0.9]  mx-auto md:mx-0 my-5 flex items-center justify-between bg-white   text-gray-900 w-[80%] md:w-[50%] px-4 rounded bg-stone-800 text-white",
};
const About = (props) => {
  return (
    <div className={style.about} style={{ fontFamily: "Montserrat,serif" }}>
      <div className={style.headingContainer}>
        <h2 className={style.heading1}>За обекта</h2>
        <p className="border-b-[6px] border-stone-400 w-[8vw] md:w-[4vw] "></p>
      </div>
      <div className="flex my-24 mx-auto">
        <div className={style.side1}>
        <p
            className={style.details}
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            Къща за гости „Крис“ разполага с 7 двойни стаи и 3 апартамента. Благодарение на уникалното
си разположение на единия хълм на гр. Девин, всички помещения разполагат с гледка към
града и планината.
          </p>
          <p
            className={style.details}
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            В обекта има ресторант, който разполага с вътрешна и външна част. Капацитетът на
вътрешната част е 50 човека. Външната част побира до 80 човека, като гостите се настаняват на
голяма тераса с уникална планинска гледка към една от най-известните местности в района.
Менюто съдържа както типични български ястия, така и такива от района – пататник, родопски
клин, качамак, както може да се насладите и на прясна планинска пъстърва.
            <br></br>
            <p className="my-3"></p>
            За най-малките гости има на разположение 2 детски къта. Единият е разположен на голяма
поляна и е оборудван с 2 пързалки, люлки, къщички и батут. Другият е предвиден при лошо
време и се намира във вътрешната част на ресторанта.
          </p>
          <p
            className={style.details}
            style={{ fontFamily: "Poppins, sans-serif" }}
          >
            На разположение на нашите гости е безплатно ползване на маса за тенис, както и различни
видове настолни игри.
          </p>
          <div className={style.divider}></div>
          {/* <div className={style.coutnerContainer}>
            <Counter />
          </div> */}
          <div className={style.btnContainer} onClick={()=>props.onScroll('reservation')}>
            <button className={style.btn}>Резервирай</button>
            <AiOutlineArrowRight className={style.arrow} />
          </div>
          <div className={style.btnContainer} onClick={()=>props.onScroll('reservation')}>
            <button className={style.btn}>За контакти</button>
            <AiOutlineArrowRight className={style.arrow1} />
          </div>
          {/* adding the signature */}
        </div>
        <div className={style.side2}>
          <img
            src={aboutimg}
            alt=""
            className={style.img}
            style={{ clipPath: "polygon(0 0, 100% 10%, 100% 88%, 1% 100%)" }}
          />
        </div>
      </div>
    </div>
  );
};

export default About;
