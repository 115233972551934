import React, { useState, useEffect } from "react";
import roomimg1 from "../../assets/rooms/1.jpg";
import roomimg2 from "../../assets/rooms/2.jpg";
import roomimg3 from "../../assets/rooms/3.jpg";
import roomimg4 from "../../assets/rooms/4.jpg";
import roomimg5 from "../../assets/rooms/5.jpg";
import roomimg6 from "../../assets/rooms/6.jpg";
import roomimg7 from "../../assets/rooms/7.jpg";
import roomimg8 from "../../assets/rooms/8.jpg";
import PropertiesNavbar from "./PropertiesNavbar";
import PropertiesGrid from "./PropertiesGrid";

const style = {
  wrapper: "flex flex-col items-center",
  headingContainer: "flex items-center jusitfy-center flex-col mb-6",
  row:'w-[25%] border-2 border-stone-800',
  cell:'p-4 text-center  border-2 border-stone-800',
  heading:
    "max-w-fit mx-auto flex items-center jusitfy-center flex-col my-8 text-gray-900 text-3xl md:text-4xl font-semibold ",
  mainHeading:
    "text-center md:text-left my-2 text-blue-900 text-3xl sm:text-4xl md:text-5xl lg:text-5xl my-6 md:my-8 mx-auto",
};
const category1Data = [
  {
    name: "Двойна стая с балкон",
    img: roomimg3,
    info1:
      "Разполагаме с два вида двойни стаи – със спалня и с две отделни легла. Всички двойни стаи имат балкон с гледка. Стаите са оборудвани с хладилник, телевизор, кана за подгряване, както и с хавлии и тоалетни принадлежности. В някои от стаите има разтегателен фотьол, който е подходящ за дете. Баните са с душ кабини.",
    info2: "Настаняване: 2 възрастни / Допълнителна възможност: 1 дете",
  },
];

const category2Data = [
  {
    name: "Суит с три стаи",

    img: roomimg7,
    info1:
      "Този суит разполага с три отделни спални помещения. В едната стая има спалня, а в другите две – по две единични легла. Във всяка стая има телевизор, а също на разположение е кана за подгряване и хладилник. Хавлии и тоалетни принадлежности има за всички гости. Банята е с душ кабина. Тозе суит е на последния етаж на къща за гости „Крис“, като от прозорците се открива гледка във всички посоки.",
    info2: "Настаняване: 6 възрастни",
  },
  {
    name: "Суит с две стаи и балкон",

    img: roomimg2,
    info1:
      "Този суит разполага с две отделни спални помещения. В едната стая има спалня, а в другата – две единични легла и разтегателен диван, който е подходящ за дете. Във всяка стая има телевизор, а също на разположение е кана за подгряване и хладилник. Хавлии и тоалетни принадлежности има за всички гости. Банята е с душ кабина. Към суита има балкон, даващ прекрасна гледка към планината.",
    info2: "Настаняване: 4 възрастни / Допълнителна възможност: 1 дете",
  },
  {
    name: "Джуниър суит с тераса",

    img: roomimg8,
    info1:
      "Този тип помощение разполага с две отделни спални помещения. В едната стая има спалня, а в другата – единично легло и разтегателен фотьол, който е подходящ за дете. Във всяка стая има телевизор, а също на разположение е кана за подгряване и хладилник. Хавлии и тоалетни принадлежности има за всички гости. Банята е с душ кабина. Този суит е с голяма тераса суникална гледка не само към града и планината, но и към градината на къща за гости „Крис“.",
    info2: "Настаняване: 3 възрастни / Допълнителна възможност: 1 дете",
  },
];
const Properties = (props) => {
  const [SelectedCategory, setSelectedCategory] = useState("1");
  const handleCategory = (value) => {
    setSelectedCategory(value);
  };
  const handleScroll = () => {
    // console.log('adf')
    props.onScroll("reservation");
  };
  return (
    <div className={style.wrapper} style={{ fontFamily: "Poppins, serif" }}>
      {/* adding the heading for the properties */}
      <div
        className={style.headingContainer}
        style={{ fontFamily: "Montserrat,serif" }}
      >
        <h2 className={style.heading}>Стаи</h2>
        <p className="border-b-[6px] border-stone-400 w-[8vw] md:w-[4vw] "></p>
      </div>
      {/* adding the navbar for the properties */}
      <PropertiesNavbar handleCategory={handleCategory} />
      <PropertiesGrid
        category1={category1Data}
        category2={category2Data}
        selectedcategory={SelectedCategory}
        onScroll={handleScroll}
      />
      {/* adding the table */}
      <div
        className={style.headingContainer}
        style={{ fontFamily: "Montserrat,serif" }}
      >
        <h2 className={style.heading}>Цени</h2>
        <p className="border-b-[6px] border-stone-400 w-[8vw] md:w-[4vw] "></p>
        <p style={{ color: 'green',  }} className="text-center my-4 md:my-3 "><strong>В сила от 01.10.2024</strong></p>
        <p style={{ color: 'red',  }} className="text-center my-4 md:my-3 "><strong>Ресторантът няма да работи на 31.12.2024</strong></p>
      </div>
      <table className="w-[95%] md:w-[70%]">
        <tr className={style.row}>
          <th className={style.cell}>Тип стая</th>
          <th className={style.cell}>петък, събота и празнични дни</th>
          <th className={style.cell}>делнични дни</th>
        </tr>
        <tr className={style.row}>
          <td className={style.cell}>Двойна стая с балкон</td>
          <td className={style.cell}>105 лв.</td>
          <td className={style.cell}>90 лв.</td>
        </tr>
        <tr className={style.row}>
          <td className={style.cell}>Джуниър суит с тераса</td>
          <td className={style.cell}>145 лв.</td>
          <td className={style.cell}>120 лв.</td>
        </tr>
        <tr className={style.row}>
          <td className={style.cell}>Суит с две стаи и балкон </td>
          <td className={style.cell}>170 лв.</td>
          <td className={style.cell}>150 лв.</td>
        </tr>
        <tr className={style.row}>
          <td className={style.cell}>Суит с три стаи</td>
          <td className={style.cell}>200 лв.</td>
          <td className={style.cell}>180 лв.</td>
        </tr>
      </table>
      <p className="text-center my-4 md:my-7">*допълнително легло - 20 лв.</p>
      <p className="text-center my-4 md:my-1">Цените включват: ДДС, закуска, застраховка и курортна такса.</p>
    </div>
  );
};

export default Properties;
