import React, { useState, useEffect } from "react";
import Signature from "./Signature";
import logoimg from '../../assets/kris_logo.png'
const style = {
  wrapper: "ml-0 bg-white-100",
  footerNav: "flex flex-col md:flex-row mt-2 md:mt-8 w-[100%] pt-12 md:py-24 border-t-[1px] border-stone-800 ",
  btnContainer: "flex md:flex-row mb-7 mt-7 justify-around",
  left: "flex flex-col items-center md:items-start justify-between md:w-[50%] mx-3 md:mx-12",
  right:
    "flex flex-col md:flex-row md:w-[50%] md:items-start md:justify-center",
  col1: "md:w-[50%] flex flex-col items-start justify-start  p-3 md:p-3",
  col2: "md:w-[50%] flex flex-col items-start justify-start  p-3 md:p-3",
  list: "text-stone-800 decoration-none list-none my-2 cursor-pointer border-move-animation1 hover:opacity-[0.85]  font-normal",
  list1:
    "text-stone-800 decoration-none list-none my-2 cursor-pointer border-move-animation1  hover:opacity-[0.85] font-normal",
};
const Footer = (props) => {
  const [Email, setEmail] = useState("");

  return (
    <div className={style.wrapper} style={{ fontFamily: "Montserrat,serif" }}>
      <div className={style.footerNav}>
        <div className={style.left}>
          <img width={190} height={190} src={logoimg} alt="this is the logo"/>
        </div>
        <div className={style.right}>
          <div className={style.col1}>
            <li className={style.list} onClick={() => props.onScroll("home")}>
              Начало
            </li>
            <li
              className={style.list}
              onClick={() => props.onScroll("aboutus")}
            >
              За обекта
            </li>
            <li className={style.list} onClick={() => props.onScroll("aboutcity")}>
              За околността
            </li>
          </div>
          <div className={style.col2}>
            <li
              className={style.list1}
              onClick={() => props.onScroll("location")}
            >
              Локация
            </li>
            <li
              className={style.list1}
              onClick={() => props.onScroll("rooms")}
            >
              Стаи
            </li>
          </div>
        </div>
      </div>

      <Signature />
    </div>
  );
};

export default Footer;
