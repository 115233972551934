import React from "react";
import ImageGallery from "react-image-gallery";
import Img1 from '../../assets/rooms/DSC_0011big.jpg'
import Img2 from '../../assets/rooms/DSC_0014big.jpg'
import Img3 from '../../assets/rooms/DSC_0015big.jpg'
import Img4 from '../../assets/rooms/DSC_0090big.jpg'
const images = [
  {
    original: Img1,
    thumbnail: Img1,
  },
  {
    original: Img2,
    thumbnail: Img2,
  },
  {
    original: Img3,
    thumbnail: Img3,
  },
  {
    original: Img4,
    thumbnail: Img4,
  },
  
];
const style = {
  wrapper: "",
};
const ImageGalleryComponent = () => {
  return (
    <div className='flex'>
      <ImageGallery items={images} showPlayButton={false} showNav={false} showFullscreenButton={false}/>
    </div>
  );
};

export default ImageGalleryComponent;