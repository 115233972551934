import React, { useState } from "react";
import useWindowSize from "../../hooks/useWindowSize";
import { AiOutlinePhone } from "react-icons/ai";
import NavDrawer from "./NavDrawer";
import "animate.css";
import logo from '../../assets/kris_logo.png'
const Navbar = (props) => {
  const style = {
    navDrawer: "flex sm:hidden items-center justify-center font1",
    wrapper:
      "absolute sm:relative text-gray-200 pt-1 md:pt-4 z-10 w-[100%] md:w-[90%] mx-auto font1",
    mainNav: "flex items-center justify-between py-1 md:py-1 hidden sm:flex",
    list: "list-none cursor-pointer text-primary md:mx-4 hover:opacity-[0.8] text-sm",
    navContainer: "flex items-center justify-center",
    cartButton:
      "flex items-center justify-center mx-5 bg-gray-200 text-gray-900 px-3 py-2 text-sm rounded hover:opacity-[0.9]",
    img: "cursor-pointer",
  };
  const [Enter, setEnter] = useState(false);
  const [Item, setItem] = useState("home");
  props.onScroll(Item);
  const handleScroll = (val) => {
    setItem(val);
  };
  return (
    // install the mui first
    <div className={style.wrapper} style={{fontFamily:'Montserrat,serif'}}  >
      <nav className={style.mainNav}>
        <div className={style.img}>
          <div href="/">
            <img src={logo} height={150} width={150} />
            {/* <h2>LOGO HERE</h2> */}
          </div>
        </div>
        <div className="flex items-center justify-center">
          <div className={style.navContainer}>
            <li className={style.list} onClick={() => setItem("home")}>
              Начало
            </li>
            <li className={style.list} onClick={() => setItem("aboutus")}>
              За обекта
            </li>
            <li className={style.list} onClick={() => setItem("aboutcity")}>
За града     </li>
            <li className={style.list} onClick={() => setItem("location")}>
               Къде се намираме?
            </li>
            <li className={style.list} onClick={() => setItem("rooms")}>
               Стаи
            </li>
          </div>
          <div>
            <button
              className={style.cartButton}
              onClick={() => setItem("reservation")}
              // onMouseEnter={() => setEnter(true)}
              // onMouseLeave={() => setEnter(false)}
            >
              <h2>Резервирай</h2>
              <AiOutlinePhone
                className={`text-2xl md:text-xl text-gray-900 mx-2 ${
                  Enter && "animate__animated animate__tada"
                }`}
              />
            </button>
          </div>
        </div>
      </nav>
      <div className={style.navDrawer}>
        <NavDrawer onScroll={handleScroll} />
      </div>
    </div>
  );
};

export default Navbar;
