import React, { useState, useEffect } from "react";
import { RiReservedLine } from "react-icons/ri";
const style = {
  wrapper: "flex flex-col w-[95%] md:w-[80%] my-2 mx-auto",
  singleProperty:
    "flex flex-col md:flex-row items-center my-6 md:my-8  px-6 md:px-8 py-8 rounded-lg w-[100%] md:w-[80%] mx-auto",
  propertyContainer: " ml-0 md:ml-12",
  name: "text-3xl md:text-3xl font-bold text-stonoe-800 text-center md:text-left my-3",
  specification: "flex flex-wrap",
  description: "text-sm my-3 text-stonoe-800  text-center md:text-left",
  singleSpecification:
    "bg-blue-500 mr-3 px-2 md:px-3 py-1 text-xs md:text-sm my-1 bg-opacity-[0.5] text-white",
  price: "font-bold text-2xl md:text-2xl text-stonoe-800  my-2 md:my-4",
  contactContainer:
    "cursor-pointer text-white bg-stone-800 flex items-center justify-center max-w-fit px-3 md:px-4 py-1 md:py-2 my-2  hover:bg-opacity-[0.8] transition duration-[300ms] mx-auto md:mx-0 mt-6",
  btn1: "text-md p-1 tracking-wide",
  wrapper1: "flex items-center flex-col md:flex-row justify-center",
  singleInput: "flex flex-col items-start mx-6 md:mx-12 my-6 md:my-1",
  input:
    "bg-blue-50 px-2 md:px-3 py-1 outline-none rounded w-[80vw] md:w-[25vw]",
  label: "text-blue-900 text-xl md:text-lg",
  btn: "bg-stone-800 text-white px-6 md:px-8 py-2 md:py-3 hover:bg-opacity-[0.92] transition duration-[300ms] mx-4 my-2",
  btnContainer: "flex",
};
const PropertiesGrid = ({
  category1,
  category2,
  selectedcategory,
  onScroll,
}) => {
  const [DataToBeUsed, setDataToBeUsed] = useState([]);
  useEffect(() => {
    if (selectedcategory == "Двойни стаи") {
      setDataToBeUsed(category1);
    }
    if (selectedcategory == "Апартаменти") {
      setDataToBeUsed(category2);
    }
  }, [category1, category2,selectedcategory]);
  return (
    <div style={{ fontFamily: "Poppins, sans-serif" }}>
      {DataToBeUsed.length > 0 &&
        DataToBeUsed.map((single) => (
          <div className={style.singleProperty} key={single.name}>
            <img src={single.img} width={350} height={350} />
            <div className={style.propertyContainer}>
              <h2 className={style.name}>{single.name}</h2>

              {/* <p
                className={style.price}
                style={{ fontFamily: "Montserrat,serif" }}
              >
                Rs.{single.price}
              </p> */}
              <p className={style.description}>
                {single.info1 ? single.info1 : ""}
              </p>
              <p className={style.description}>
                {single.info2 ? single.info2 : ""}
              </p>
              <a className={style.contactContainer} onClick={() => onScroll()}>
                <RiReservedLine className="text-white mx-1 text-2xl" />
                <button className={style.btn1}>Резервирай</button>
              </a>
            </div>
          </div>
        ))}
    </div>
  );
};

export default PropertiesGrid;
