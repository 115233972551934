import React from "react";
import ReservationForm from "./ReservationForm";
import { AiOutlineMail, AiOutlinePhone } from "react-icons/ai";
const style = {
  wrapper:
    "w-[100%] md:w-[55%] lg:w-[50%] mx-auto font py-8 md:py-12 border-[0px] border-stone-800 my-8 md:my-12 rounded-xl",
  headingContainer: "flex items-center jusitfy-center flex-col mb-6",
  heading:
    "max-w-fit mx-auto flex items-center jusitfy-center flex-col my-8 text-gray-900 text-3xl md:text-4xl font-semibold ",
};
const Contact = () => {
  return (
    <div className={style.wrapper} style={{ fontFamily: "Montserrat,serif" }}>
      {" "}
      <div
        className={style.headingContainer}
        style={{ fontFamily: "Montserrat,serif" }}
      >
        <h2 className={style.heading}>Резервация</h2>
        <p className="border-b-[6px] border-stone-400 w-[8vw] md:w-[4vw] "></p>
      </div>
      <ReservationForm />
    </div>
  );
};

export default Contact;
