import React from "react";
import SingleCard from "./SingleCard";
import { MdOutlinePayments } from "react-icons/md";
import { BiBusSchool, BiDirections } from "react-icons/bi";
import { CiDiscount1 } from "react-icons/ci";
import { BsTelephoneOutbound,BsHeadphones } from "react-icons/bs";
const style = {
  card: "flex flex-col my-1 md:mb-24 md:mt-1 ",
  cardContainer: "flex flex-wrap items-center justify-center gap-2 md:gap-12",
  headingContainer: "flex items-center jusitfy-center flex-col mb-6",
  heading:
    "max-w-fit mx-auto flex items-center jusitfy-center flex-col my-8 text-gray-900 text-3xl md:text-4xl font-semibold ",
};
const Card = () => {
  return (
    <div className={style.card} style={{ fontFamily: "Montserrat,serif" }}>
      <div className={style.headingContainer}>
        <h2 className={style.heading}>Какво предлагаме</h2>
        <p className="border-b-[6px] border-stone-400 w-[8vw] md:w-[4vw] "></p>
      </div>
      {/* adding the cards */}
      <div className={style.cardContainer}>
        <SingleCard heading="Транспорт" icon={<BiBusSchool />} />
        <SingleCard heading="Отстъпки" icon={<CiDiscount1 />} />
        <SingleCard heading="Развлечения" icon={<BsHeadphones />} />
        <SingleCard heading="Планински обиколки" icon={<BiDirections />} />
      </div>
    </div>
  );
};

export default Card;
