import React, { useState } from "react";
import Home from "./components/Home/Home";
import Card from "./components/Facilities/Facilities";
import Infrastructure from "./components/City/City";
import Future from "./components/Future/Future";
import Explain from "./components/Explain/Explain";
import Counter from "./components/Counter/Counter";
import Footer from "./components/Footer/Footer";
import Animator from "./components/UI/Animator";
import { scroller } from "react-scroll";
import About from "./components/About/About";
import Reservation from "./components/Reservation/Reservation";
import Properties from "./components/Properties/Properties";
import Contact from "./components/Contact/Contact";
const style = {
  wrapper: "overflow-x-hidden w-[100vw]",
};
export default function Main() {
  const onScroll = (value) => {
    if (typeof window !== "undefined" && value !== 'home') {
      // browser code
      scroller.scrollTo(`${value}`, {
        duration: 900,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }
  };
  return (
    <Animator>
      <div className={style.wrapper}>
        <div className="home">
        <Home onScroll={onScroll} />
        </div>
        <div className="aboutus">
          <About onScroll={onScroll}/>
        </div>
        <div className="services">
          <Card />
        </div>
        <div className="aboutcity">
          <Infrastructure />
        </div>
        <div className="rooms">

        <Properties onScroll={onScroll}/>
        </div>
        <div className="location">
          <Explain />
        </div>
        <div className="contact">
          <Contact />
        </div>
        <div className="reservation">
          <Reservation />
        </div>
        <div className="achievements">
          <Counter />
        </div>
        <Footer onScroll={onScroll} />
      </div>
    </Animator>
  );
}
export async function getStaticProps(context) {
  return {
    props: {}, // will be passed to the page component as props
  };
}
