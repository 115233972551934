import React, { useState } from "react";

const style = {
  wrapper: "flex items-center w-[100vw] md:w-[55vw] flex mx-auto my-12",
};
const PropertiesNavbar = ({handleCategory}) => {
  const [Category, setCategory] = useState("Двойни стаи");
  const handleCategoryChange = (value) => {
    setCategory(value);
  };
  handleCategory(Category);
  return (
    <div className={style.wrapper}>
      <li
        className={`mx-1 w-[50%] text-center  ${
          Category == "Двойни стаи"
            ? "border-b-2 border-stone-800 text-stone-800"
            : "bg-white text-stone-800"
        } px-3 py-2 md:px-6 md:py-2 decoration-none list-none cursor-pointer  transition duration-[300ms]`}
        onClick={() => handleCategoryChange("Двойни стаи")}
      >
        Двойни стаи
      </li>
      <li
        className={`mx-1 w-[50%] text-center  ${
          Category == "Апартаменти"
            ? "border-b-2 border-stone-800 text-stone-800"
            : "bg-white text-stone-800"
        }  px-3 py-2 md:px-6 md:py-2 decoration-none list-none  cursor-pointer  transition duration-[300ms]`}
        onClick={() => handleCategoryChange("Апартаменти")}
      >
        Апартаменти
      </li>
    </div>
  );
};

export default PropertiesNavbar;
