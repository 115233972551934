import React from "react";
import useWindowSize from "../../hooks/useWindowSize";
const Explain = () => {
  const style = {
    wrapper: "flex py-12 md:py-2 w-[100%] md:w-[45%]",
    headingContainer: "flex items-center jusitfy-center flex-col mb-6",
    heading:
      "max-w-fit mx-auto flex items-center jusitfy-center flex-col my-8 text-gray-900 text-3xl md:text-4xl font-semibold ",
  };
  const { width } = useWindowSize();
  return (
    <div className="h-full w-full sm:w-[95%] md:w-[80%] h-[80%] flex mx-auto flex-col px-4 py-8 md:pb-24">
      <div
        className={style.headingContainer}
        style={{ fontFamily: "Montserrat,serif" }}
      >
        <h2 className={style.heading}>Къде се намираме</h2>
        <p className="border-b-[6px] border-stone-400 w-[8vw] md:w-[4vw] "></p>
      </div>
      <div
        style={{ fontFamily: "Poppins,serif" }}
        className="flex items-center justify-between flex-col md:flex-row flex-col-reverse "
      >
        <div className={style.wrapper}>
          <div
            style={{
              width:
                width < 500
                  ? "100%"
                  : width > 500 && width < 1200
                  ? "90%"
                  : "100%",
            }}
          >
            <iframe
              width="100%"
              height={
                width < 500
                  ? "400"
                  : width > 500 && width < 1200
                  ? "450"
                  : "400"
              }
              frameborder="0"
              marginheight="0"
              marginwidth="0"
              src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Kris%20Hotel,%20%D1%83%D0%BB.%20%D0%92%D1%8A%D1%81%D1%82%D0%B0%D0%BD%D0%B8%D0%BA%2010,%204800%20Devin+(My%20Business%20Name)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"
            >
              <a href="https://www.maps.ie/distance-area-calculator.html">
                distance maps
              </a>
            </iframe>
          </div>
        </div>
        <section className="flex w-[100%] sm:w-[50%] flex-col">
          <section className="flex">
            <HeadingAndContent
              heading={"За града"}
              paragraph={
                "Още от древни времена районът на гр. Девин е привличал заселници. Разнообразният релеф, тучните поляни, обширните смърчови и борови гори, природните резервати, мекият климат и многобройните минерални извори правят региона неповторим за посетителите. Днес градът предлага невероятни възможности за пълноценна и здравословна почивка, близост с природата и неповторими преживявания. Само на 210 км от София, 90 км от Пловдив и 30 км от курорта Пампорово може да намерите хотел Крис, кацнал на на един от хълмовете на китното планинско градче Девин в Родопите. През месец октоври 2004 година той отваря своите врати, за да посреща гости, търсещи спокойствието, тишината, красотата и прохладата на величествените Родопи."
              }
            />
          </section>
        </section>
      </div>
    </div>
  );
};

const HeadingAndContent = (props) => {
  return (
    <div className="flex flex-col h-full w-full">
      <h2 className="text-gray-900 text-2xl sm:text-xl md:text-4xl my-3 sm:my-3 md:my-5 text-center sm:text-left ">
        {props.heading}
      </h2>
      <p className="flex text-md sm:text-md md:text-lg leading-[1.5rem] text-gray-800 text-center md:text-left">
        {props.paragraph}
      </p>
    </div>
  );
};
export default Explain;

