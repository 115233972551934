import React from "react";

const InfrastructureContent = () => {
  return (
    <div className="flex w-full h-full flex-col mx-auto font">
      <section className="h-[30%] flex mx-auto" style={{ fontFamily: "Montserrat,serif" }}>
        <h1 className=" my-auto text-white flex text-4xl sm:text-3xl md:text-4xl text-center font-semibold">
          Къде се намираме{" "}
        </h1>
      </section>
      <p style={{ fontFamily: "Poppins,serif" }} className="text-stone-300 flex text-sm sm:text-md md:text-lg h-[50%] md:h-[70%] m-auto my-7 md:my-12 indent-20 tracking-wide w-[80%] sm:w-[100%] leading-6 font-extralight">
      Къща за гости „Крис“е в самия град Девин, област Смолян. Намира се на 210 км от София, на
90 км от Пловдив и само на 30 км от курорта Пампорово. Разположена в Западните Родопи, на
820 м надморска височина.
      </p>
      <p style={{ fontFamily: "Poppins,serif" }} className="text-stone-300 flex text-sm sm:text-md md:text-lg h-[50%] md:h-[70%] m-auto my-7 md:my-12 indent-20 tracking-wide w-[80%] sm:w-[100%] leading-6 font-extralight">
      Разположението му на един от хълмомете на града, предоставя на гостите гледка
едновременно на невероятната Родопа планина, но и птичи поглед към града.
      </p>
    </div>
  );
};

export default InfrastructureContent;
